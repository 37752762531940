import { Container, Row, Spinner, Message, Anchor, IconSvg } from '@prism/library';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom'
import MessageWrapper from './components/message-wrapper/MessageWrapper';
import styles from './Finalize.module.scss'
import cx from 'classnames';

function callFinalize(domain,username,uuid,otp,callback){
    fetch(`/api/finalize?domain=${encodeURIComponent(domain)}&username=${encodeURIComponent(username)}&uuid=${encodeURIComponent(uuid)}&otp=${encodeURIComponent(otp)}`)
        .then(res => callback(null, res.status))
        .catch(err => callback(err,-1));
}

function Finalize(){
    let query = new URLSearchParams(useLocation().search);
    let domain = query.get('domain');
    let username = query.get('username');
    let uuid = query.get('uuid');
    let otp = query.get('otp');

    let content_waiting=(
        <Container fluid>
            <Row className="justify-content-md-center"><Spinner color="primary"/></Row>
            <Row className="justify-content-md-center">Validating Your Information... </Row>
        </Container>
    );
    let content_done = (
      <Container fluid>
        <Row className="justify-content-md-center">
          <MessageWrapper>
            <div className={styles.doneContainer}>
              <div className={styles.doneContentWrapper}>
                <div className="align-items-center d-flex flex-column">
                <IconSvg glyph="checkmark-circle" color="success" className="text-jungle" height="60px" />
                  <h1 className="text-jungle mb-4 mt-3">Verified!</h1>
                </div>
                <div className={cx('text-center', styles.doneContentText)}>
                  Thank you for verifying your contact information.
                </div>
              </div>

              <div>
                <p className="text-center">
                  You may close this window or continue to&nbsp;
                  <Anchor
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('https://www.manheim.com', '_self');
                    }}
                  >
                    Manheim.com.
                  </Anchor>
                </p>
              </div>
            </div>
          </MessageWrapper>
        </Row>
      </Container>
    );
    let content_error = (
      <Container fluid>
        <Row className="justify-content-md-center">
          <MessageWrapper>
            <div className="py-5 px-3">
              <Message isOpen={true} color="danger">
                This link has expired. Please logout and log in to verify your
                information. If you need additional assistance, please call
                Manheim Client Care at 
                <b>1-866-626-4346</b>.
              </Message>
            </div>
          </MessageWrapper>
        </Row>
      </Container>
    );

    const [content,setContent] = useState(content_waiting);
    useEffect(()=>{
        if(!query || !domain || !username || !uuid || !otp){
            setContent(content_error);
        }else{
            callFinalize(domain,username,uuid,otp,
        function(error,code){
                    if(code === 204){
                        setContent(content_done);
                    }else{
                        setContent(content_error);
                    }
                }
            );
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Container className={styles.container}>{content}</Container>;

}

export default Finalize; 