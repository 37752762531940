import classnames from 'classnames';
import React, { useState } from 'react';
import Header from './components/Header';
import {
  constrainDate, toDate, preventDefaultEvent,
} from './utils';

const useDatePicker = (props) => {
  const {
    afterCalendarClose,
    afterClickOutside,
    className,
    datePickerRef,
    defaultValue,
    readonly,
    disabled,
    endDate: initialEndDate,
    forceShowDate,
    hasMessage,
    inputClassName,
    invalid,
    label,
    maxDate,
    minDate,
    nullAllowed,
    onChange,
    placeholder,
    selectsRange,
    size,
    startDate: initialStartDate,
    triggerClassName,
    valid,
    ...attrs
  } = props;

  const parsedMinDate = toDate(minDate);
  const parsedMaxDate = toDate(maxDate);
  const dateValue = constrainDate(toDate(defaultValue), {
    min: parsedMinDate,
    max: parsedMaxDate,
  });
  const minSelectableMonth = new Date(parsedMinDate).setDate(1);

  const [isMonthPicker, setIsMonthPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dateValue);
  const [dateRange, setDateRange] = useState(() => {
    if (initialStartDate === null) {
      return [null, null];
    }
    const computedStartDate = new Date(initialStartDate);
    if (initialEndDate === null) {
      return [computedStartDate, null];
    }
    const computedEndDate = new Date(initialEndDate);
    if (computedStartDate <= computedEndDate) {
      return [computedStartDate, computedEndDate];
    }
    return [null, null];
  });

  const [startDate, endDate] = dateRange;

  const handleClickOutside = () => {
    setIsMonthPicker(false);
    if (afterClickOutside) {
      afterClickOutside();
    }
  };

  const handleCalendarClose = () => {
    setIsMonthPicker(false);
    if (afterCalendarClose) {
      afterCalendarClose();
    }
  };

  const handleDateChange = (date) => {
    const newDate = date === null && nullAllowed
      ? null
      : constrainDate(toDate(date), {
        min: parsedMinDate,
        max: parsedMaxDate,
      });
    setIsMonthPicker(false);
    setSelectedDate(newDate);
    onChange(newDate);
  };

  const handleDateRangeChange = (update) => {
    const newDateRange = update || [null, null];

    if (!isMonthPicker) {
      setDateRange(newDateRange);
    }
    setIsMonthPicker(false);
    onChange(newDateRange);

    const [newStartDate, newEndDate] = newDateRange;
    if (newStartDate && newEndDate) {
      datePickerRef.current.setOpen(false);
    }
  };

  const computedClassName = classnames({
    'date-picker': true,
    picker: true,
    [`picker-${size}`]: size === 'sm',
    'picker--range': selectsRange,
    [className]: className,
    'has-message': !!hasMessage,
    'is-valid': !!valid,
    'is-invalid': !!invalid,
  });

  const computedInputClassName = classnames({
    'date-picker-input': true,
    'form-control': true,
    'inset-right': true,
    [`form-control-${size}`]: size === 'sm',
    [inputClassName]: inputClassName,
    'is-valid': valid,
    'is-invalid': invalid,
    'has-validation': !!hasMessage,
    'is-readonly': readonly,
    clearable: selectsRange && startDate,
  });

  const computedTriggerClassName = classnames({
    'date-picker-trigger': true,
    btn: true,
    'input-group-action': true,
    [`btn-${size}`]: size === 'sm',
    [triggerClassName]: triggerClassName,
  });

  const computedPlaceholder = placeholder || (selectsRange ? 'Select date range' : 'MM/DD/YYYY');

  const computeInputValue = () => {
    if (selectsRange) {
      if (!startDate && !endDate) {
        return '';
      }
      const startDateValue = startDate?.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }) || '';
      const endDateValue = endDate?.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }) || '';
      return `${startDateValue} - ${endDateValue}`;
    }
    return selectedDate;
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
  }) => (
    <Header
      selectedDate={date}
      isMonthPicker={isMonthPicker}
      decreaseYear={preventDefaultEvent(decreaseYear)}
      increaseYear={preventDefaultEvent(increaseYear)}
      decreaseMonth={preventDefaultEvent(decreaseMonth)}
      increaseMonth={preventDefaultEvent(increaseMonth)}
      showMonthPicker={() => setIsMonthPicker(true)}
    />
  );

  const datePickerProps = {
    ...attrs,
    className: computedInputClassName,
    disabled,
    isClearable: selectsRange && startDate,
    formatWeekDay: (dayOfWeekLabel) => dayOfWeekLabel.substring(0, 1),
    maxDate: parsedMaxDate,
    minDate: isMonthPicker ? minSelectableMonth : parsedMinDate,
    onClickOutside: handleClickOutside,
    onCalendarClose: handleCalendarClose,
    onChange: selectsRange ? handleDateRangeChange : handleDateChange,
    openToDate: selectedDate ? selectedDate : dateValue,
    placeholderText: computedPlaceholder,
    readOnly: readonly,
    ref: datePickerRef,
    renderCustomHeader,
    selectsRange,
    shouldCloseOnSelect: !selectsRange && !isMonthPicker,
    showMonthYearPicker: isMonthPicker,
    value: computeInputValue(),
    // single date picker
    selected: selectsRange ? false : selectedDate,
    // date range picker
    startDate: selectsRange ? startDate : false,
    endDate: selectsRange ? endDate : false,
  };

  return {
    className: computedClassName,
    triggerClassName: computedTriggerClassName,
    datePickerProps,
    isMonthPicker,
  };
};

export default useDatePicker;
