import React from 'react';
import {Container, Row} from "@prism/library";



function Default() {
    return (
        <Container fluid>
            <Row className="justify-content-md-center">Nothing to see here...</Row>
        </Container>
    );
}
export default Default;