import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Finalize from './Finalize';
import Default from './Default';

function App() {
  return (
   <Router>
       <Switch>
           <Route path="/finalize">
               <Finalize/>
           </Route>
           <Route>
               <Default/>
           </Route>
       </Switch>
   </Router>
  );
}
export default App;