import React from 'react';
import styles from './styles.module.scss';

export default function MessageWrapper({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <img
          className={styles.logo}
          src={process.env.PUBLIC_URL + '/manheimLogo.svg'}
          alt="logo"
        />
      </div>
      {children}
    </div>
  );
}
